<template>
  <div class="bg">
    <Header />
    <Content>
      <template v-slot:content>
        <router-view />
      </template>
    </Content>
  </div>
</template>
<script>
import Vue from 'vue';
import Header from '@/components/Layout/Header.vue';
// import Sidebar from '@/components/Layout/Sidebar.vue';
import Content from '@/components/Layout/Content.vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Api from '@/Api';

Vue.use(VueToast);
export default {
  components: {
    Header,
    Content,
  },
  async mounted() {
    try {
      const data = await Api.get('/api/collections');
      debugger;
      this.$store.dispatch('app/storeCollections', data.data);
    } catch (e) {
      this.$toast.open({
        message: 'Something went wrong while fetching collections!',
        type: 'error',
        position: 'top-right',
        duration: 10000,
        // all of other options may go here
      });
    }
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.bg {
  background: linear-gradient(180deg, #0b101a 0% 100%);
}

.heading {
  font-weight: 700;
  font-size: 80px;
  line-height: 80px;
  /* or 100% */

  text-transform: capitalize;

  /* Uni WHite */

  color: #FFFFFF;
  text-align: left;
  margin: 0 0 15px;
}
.sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #FFFFFF;
}

.sub-heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
  color: #000000;
  margin-bottom: 40px;
}

.main-hold {
  border: 1px solid #0c101b;
  min-height: 100vh;
}

.each {
  padding: 40px;
  border-bottom: 1px solid #0c101b;
  h3 {
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1100px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1100px;
  }
}

.flex {
  display: flex;
  justify-content: center;
}
.tabs {
  padding-top: 42px;
}
.nav-pills {
  padding-bottom: 37px !important;
  .nav-item a{
    padding: 16px 28px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    border-radius: 4px;
    color: #0C101B;
  }
  .nav-item a.active {
    background: #0C101B;
  }
}
.btn-active {
  background: #151A24 !important;
  color:#fff !important;
  display: block !important;
  width: 100% !important;
  padding: 20px !important;
}

.btn-loading {
  @extend .btn-active;
  background: rgba(1, 230, 211, 0.1) !important;
  color: #01E6D3 !important;
  border: 2px solid #01E6D3 !important;
}
.btn-inactive {
  background: rgb(255, 255, 255) !important;
  color: rgb(120, 120, 120) !important;
  display: block !important;
  width: 100% !important;
  padding: 20px !important;
  border-color: rgb(120, 120, 120) !important;
}

.v-toast__item--success {
    background: rgba(1, 230, 211, 0.1);
    border: 2px solid #01E6D3;
    color: #056277;
}

.v-toast__item .v-toast__text {
    padding: 0.5em;
}

.v-toast__item.v-toast__item--success .v-toast__icon {
  background: url('~@/assets/tick.png') no-repeat;
}

.v-toast__item {
  min-height: 50px;
}

.v-toast__item--error {
    background-color: #f7471c;
    background: #FFFAFA;
    border: 1px solid #FF0000;
    color: #0C101B;
}

.v-toast__item.v-toast__item--error .v-toast__icon {
  background: url('~@/assets/error.png') no-repeat;
}
.healthy {
  color: #00B1A3 !important;
}
.un-healthy {
  color: #FF4004 !important;
}
</style>
