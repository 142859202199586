/* eslint-disable */
import Api from '@/Api';
import {
  connectWallet,
  signMessage,
  getAccount,
} from '@/contracts/index';

// initial state
const state = () => ({
  isConnected: false,
  account: null,
  collections: [],
});

// getters
const getters = {
  // eslint-disable-next-line
  getIsConnected: (state, getters, rootState) => {
    return state.isConnected;
  },
  // eslint-disable-next-line
  getAccount: (state, getters, rootState) => {
    return state.account;
  },
  // eslint-disable-next-line
  getCollections: (state, getters, rootState) => {
    return state.collections;
  },
  getCollectionByid: (state, getters, rootState) => (assetContractId) => {
    return state.collections.find(item => item.testAssetContractAddress.toLowerCase() === assetContractId.toLowerCase());
  },
};

// actions
const actions = {
  // eslint-disable-next-line
  async connectWallet({ commit, state }, callbackFn) {
    try {
      const account = await getAccount();
      const expiry = localStorage.getItem('expiry');
      if (new Date() > new Date(expiry) && account) {
        const d = new Date();
        // Get Nonce
        const res = await Api.post(`/api/users/${account}/nonce`);
        const { signature } = await signMessage(`Nonce: ${res.data}`);
        const resData = await Api.post(`/api/users/${account}/login`, {
          signature,
        });
        localStorage.setItem('token', resData.data.token);
        // eslint-disable-next-line
        Api.defaults.headers.common['Authorization'] = `${resData.data.token}`;
        d.setDate(d.getDate() + 1);
        localStorage.setItem('expiry', d.getTime());
        localStorage.setItem('user', JSON.stringify(resData.data.user));
        commit('setIsConnected', true);
        commit('setAccount', account);
        // self.isConnected = true;
        // self.account = account;
      }
      await connectWallet();
      callbackFn();
    } catch(e) {
      console.log(e);
    }
  },
  updateAccount({ commit, state }, {isConnected, account}) {
    commit('setIsConnected', isConnected);
    commit('setAccount', account)
  },
  storeCollections({ commit, state }, collections) {
    commit('setCollections', collections);
  }
};

// mutations
const mutations = {
  setIsConnected(state, isConnected) {
    state.isConnected = isConnected;
  },

  setAccount(state, account) {
    state.account = account;
  },
  setCollections(state, collections) {
    state.collections = collections;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
