import Vue from 'vue';
import VueRouter from 'vue-router';
import { getAccount } from '@/contracts/index';

const Home = () => import(/* webpackChunkName: 'home' */ '../views/HomeView.vue');
const Dashboard = () => import(/* webpackChunkName: 'dashboard' */ '../views/DashboardView.vue');

Vue.use(VueRouter);
async function checkAuth() {
  const expiry = localStorage.getItem('expiry');
  const account = await getAccount();
  if (!account) {
    return false;
  }
  if (new Date() > new Date(expiry)) {
    return false;
  }

  return true;
}

async function isAuthenticated(to, from, next) {
  const status = await checkAuth();
  if (status) {
    next();
  } else {
    next('/');
  }
}

async function isNotAuthenticated(to, from, next) {
  const status = await checkAuth();
  if (!status) {
    next();
  } else {
    next('/dashboard');
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: isNotAuthenticated,
  },
  {
    path: '/dashboard',
    beforeEnter: isAuthenticated,
    name: 'home',
    component: Dashboard,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
