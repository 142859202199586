<template>
  <div>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'AppContent',
};
</script>
