// POST CALLS
// https://github.com/BendDAO/bend-lending-protocol/blob/main/contracts/protocol/WETHGateway.sol
// GET CALLS
// https://github.com/BendDAO/bend-lending-protocol/blob/main/contracts/misc/UiPoolDataProvider.sol

/* eslint-disable */
import * as Web3 from "web3";
export let web3Provider =
  window.web3 && window.web3.currentProvider ?
    window.web3.currentProvider
    : new Web3.providers.HttpProvider(
      "https://goerli.infura.io/v3/d0f77a6549204fd79091d8cee26ff1cb"
    );
// Replace this with Redux for more complex logic
const networkCallbacks = [];

// export const WETHGateway = '0x21950c9b7F35c15C2F7CeDa564206c425acB54Fd';
// import { WETHGateway } from './CONTRACTS';
var useraddress = "";
var web3;
export const onNetworkUpdate = (callback) => {
  networkCallbacks.push(callback)
}

export async function connectWallet() {
  // console.log(window.ethereum.isConnected())
  if (window.web3 == undefined) {
    const errorMessage = "You need an Ethereum wallet to interact with this marketplace. Unlock your wallet, get MetaMask.io or Portis on desktop, or get Trust Wallet or Coinbase Wallet on mobile."
    // web3Provider = new PortisProvider({
    //   // Put your Portis API key here
    // })
    // alert(errorMessage)
    throw new Error(errorMessage)
  } else if (window.ethereum) {
    window.ethereum.enable();
    window.web3 = new Web3(window.ethereum);
    web3 = window.web3;
    getAccount();
  } else {
    const errorMessage = "You need an Ethereum wallet to interact with this marketplace. Unlock your wallet, get MetaMask.io or Portis on desktop, or get Trust Wallet or Coinbase Wallet on mobile."
    alert(errorMessage)
    throw new Error(errorMessage)
  }
  networkCallbacks.map((c) => c(web3Provider))
}

export async function checkmetamaskstatus() {
  if (window.ethereum != undefined) {
    return new Promise((resolve) => {
      window.web3 = new Web3(window.ethereum);
      web3 = window.web3;
      window.web3.eth.getAccounts((err, retAccount) => {
        console.log(retAccount);
        if (retAccount.length > 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    })
  }
}

export async function getAccount() {
  return new Promise((resolve, reject) => {
    if (window.ethereum != undefined) {
      window.web3 = new Web3(window.ethereum);
      web3 = window.web3;
      window.web3.eth.getAccounts((err, retAccount) => {
        console.log(retAccount);
        if (retAccount.length > 0) {
          useraddress = retAccount[0];
          console.log(retAccount[0], useraddress);
          //getUserBalance();
          resolve(useraddress);
          //return retAccount[0];
        } else {
          console.log("transfer.service :: getAccount :: no accounts found.");
          resolve("")
        }
        if (err != null) {
          //alert("transfer.service :: getAccount :: error retrieving account");

        }
      });
    } else {
      resolve("");
    }
  })
}

export async function getNetworkId() {
  return new Promise((resolve, reject) => {
    window.web3 = new Web3(window.ethereum);
    web3 = window.web3;
    window.web3.eth.net.getId().then((networkId) => {
      return resolve(networkId);
    }).catch((err) => {
      return resolve('');
    });
  })
}


export async function getUserBalance() {
  return new Promise(async(resolve, reject) => {
    const account = await getAccount();
    console.log(account);
    window.web3.eth.getBalance(account, function (err, balance) {

      console.log(balance);
      if (!err) {
        const retVal = {
          account: account,
          balance: balance
        };
        resolve(retVal);

      } else {
        //  return({account: "error", balance: 0});
        reject();
      }
    });
  })

}

export async function signMessage(message) {
  const hash = window.web3.utils.sha3(message)
  const accounts = await web3.eth.getAccounts()
  const signature = await window.web3.eth.personal.sign(hash, accounts[0]);
  return { signature, hash };
}