import axios from 'axios';

console.log(process.env.VUE_APP_API);
const Api = axios.create({
  baseURL: process.env.VUE_APP_API,
});
// eslint-disable-next-line
Api.interceptors.response.use((response) => response,
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.data.code === 377) {
      localStorage.removeItem('token');
      localStorage.removeItem('expiry');
      localStorage.removeItem('user');

      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export default Api;
