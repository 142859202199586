<template>
  <b-navbar class="bg" toggleable="lg" type="dark" variant="info">
    <b-container>
      <router-link to="/" class="navbar-brand">
        <img
          alt="Vue logo"
          style="max-height: 35px"
          src="../../assets/logo.svg"
        />
      </router-link>

      <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="nav-links">
          <b-nav-item href="/whitepaper.pdf">
            <a href="/whitepaper.pdf" target="_blank" class="nav-link active"
              >Docs
            </a>
          </b-nav-item>
          <b-nav-item href="https://nft.unicred.org/" class="nav-link active" target="_blank">
              Nfts
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
        <b-button
          size="sm"
          class="my-2 my-sm-0 connect"
          @click="onConnectWallet"
          v-if="!isConnected"
        >
          <img
            alt="Vue logo"
            style="max-height: 35px"
            src="../../assets/wallet.svg"
          />Connect Wallet
        </b-button>
        <div v-else class="profile-holder">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <img
                alt="Vue logo"
                style="max-height: 35px"
                src="../../assets/profile.png"
              />
              <span>{{ account }}</span>
            </template>
            <b-dropdown-item href="#" @click="disconnect">Disconnect</b-dropdown-item>
          </b-nav-item-dropdown>
        </div>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
import {
  BContainer, BNavbar, BCollapse, BNavbarNav, BNavItem, BButton, BNavItemDropdown, BDropdownItem,
} from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import {
  signMessage,
  getAccount,
  getNetworkId,
} from '@/contracts/index';
import Api from '@/Api';

export default {
  name: 'AppHeader',
  components: {
    BNavbar,
    BCollapse,
    BNavbarNav,
    BNavItem,
    BButton,
    BContainer,
    BNavItemDropdown,
    BDropdownItem,
  },
  computed: {
    ...mapGetters('app', {
      isConnected: 'getIsConnected',
      account: 'getAccount',
    }),
  },
  data() {
    return {
      accountData: '',
      isAlert: false,
    };
  },
  async mounted() {
    // verify session
    const token = `${localStorage.getItem('token')}`;
    if (token) {
      // eslint-disable-next-line
      Api.defaults.headers.common['Authorization'] = token;
    }
    const expiry = localStorage.getItem('expiry');
    const networkId = await getNetworkId();
    if (networkId !== 5) {
      alert('Please connect to Goerly network.');
    }
    const account = await getAccount();
    if (!account) {
      this.$store.dispatch('app/updateAccount', { isConnected: false, account: null });
      return;
    }
    if (new Date() > new Date(expiry)) {
      localStorage.removeItem('token');
      localStorage.removeItem('expiry');
      localStorage.removeItem('user');
      this.$store.dispatch('app/updateAccount', { isConnected: false, account: null });
    } else {
      this.$store.dispatch('app/updateAccount', { isConnected: true, account });
      // eslint-disable-next-line
      Api.defaults.headers.common['Authorization'] = `${localStorage.getItem(
        'token',
      )}`;
    }
    window.ethereum.on('networkChanged', function (changedNetworkId) {
      if (changedNetworkId !== '5' && !this.isAlert) {
        this.isAlert = true;
        alert('Please connect to Goerly network.');
      } else {
        this.isAlert = false;
      }
    });
    window.ethereum.on('accountsChanged', async (accounts) => {
      const changedAcc = accounts[0];
      if (changedAcc !== account) {
        localStorage.removeItem('token');
        localStorage.removeItem('expiry');
        localStorage.removeItem('user');
        // this.isConnected = false;
        this.$store.dispatch('app/updateAccount', { isConnected: false, account: null });
        window.location.reload();
      }
    });
  },
  methods: {
    onWalletConnect() {
      this.$router.push('/dashboard');
    },
    disconnect() {
      localStorage.removeItem('token');
      localStorage.removeItem('expiry');
      localStorage.removeItem('user');
      window.location.reload();
    },
    async onConnectWallet() {
      this.$store.dispatch('app/connectWallet', this.onWalletConnect);
      // const account = await getAccount();
      // const expiry = localStorage.getItem('expiry');
      // const self = this;
      // if (new Date() > new Date(expiry) && account) {
      //   const d = new Date();
      //   // Get Nonce
      //   const res = await Api.post(`/api/users/${account}/nonce`);
      //   const { signature } = await signMessage(`Nonce: ${res.data}`);
      //   const resData = await Api.post(`/api/users/${account}/login`, {
      //     signature,
      //   });
      //   localStorage.setItem('token', resData.data.token);
      //   // eslint-disable-next-line
      //   Api.defaults.headers.common['Authorization'] = `${resData.data.token}`;
      //   d.setDate(d.getDate() + 1);
      //   localStorage.setItem('expiry', d.getTime());
      //   localStorage.setItem('user', JSON.stringify(resData.data.user));
      //   self.isConnected = true;
      //   self.account = account;
      //   self.$router.push('/dashboard');
      // }
      // Borrow Eth
      // await approveDelegation();
      // await setApprovalForAll("25250");
      // borrowETH(".0001", "0x9c235df4053a415f028b8386ed13ae8162843a6e", "25251");
      // eslint-disable-next-line
      // repayETH("100000044295562", "0x9c235df4053a415f028b8386ed13ae8162843a6e", "25251");
      // REPAY CALL
      // getUserReservesData();
      window.ethereum.on('accountsChanged', async (accounts) => {
        try {
          const d = new Date();
          const accountChanged = accounts[0];
          // Get Nonce
          const res = await Api.post(`/api/users/${accountChanged}/nonce`);
          const { signature } = await signMessage(`Nonce: ${res.data}`);
          const resData = await Api.post(`/api/users/${accountChanged}/login`, {
            signature,
          });
          localStorage.setItem('token', resData.data.token);
          // eslint-disable-next-line
          Api.defaults.headers['Authorization'] = `${resData.data.token}`;
          d.setDate(d.getDate() + 1);
          localStorage.setItem('expiry', d.getTime());
          localStorage.setItem('user', JSON.stringify(resData.data.user));
          // self.isConnected = true;
          // self.account = accountChanged;
          this.$store.dispatch('app/updateAccount', { isConnected: true, account: accountChanged });
          this.$router.push('/dashboard');
        } catch (e) {
          console.log(e);
        }
      });
      // await connectWallet();
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background: transparent !important;
  max-height: 64px;
}

.nav-links {
  margin-left: auto !important;
  margin-right: 55px;
}
.connect {
  background: #01CEC5 !important;
  border-radius: 0px 0px 4px 4px;
  padding: 10px 20px;
  color: #0C101B;
  img {
    margin-right: 10px;
  }
}

.nav-link {
  font-weight: 500;
  color: #fff;
  font-size: 16px;
}

nav {
    padding:5px 30px;
}

.profile-holder {
  background: #056277;
  border-radius: 4px;
  color: #F5F5F5;
  padding: 8px;
  img {
    margin-right: 10px;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    max-width: 82px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
